export const calcWheelHeight = (tyre_width, tyre_height, rim_inch) => {
    const wheelHeight = (((parseInt(tyre_height) / 100) * parseInt(tyre_width)) * 2) + (parseInt(rim_inch) * 25.4);

    if (wheelHeight.toString().length > 12) {
        return parseFloat(wheelHeight.toPrecision(wheelHeight.toString().length - 2));
    }

    return wheelHeight;
}

export const calcWheelHeightInch = (wheelHeight) => {
    return Math.ceil(wheelHeight / 25.4);
}

export const calcSpeed = (wheelHeight, drivetrain_gear, drivetrain_differential, rpm) => {
    return Math.round(
        ((((wheelHeight * 3.14) / (drivetrain_differential * drivetrain_gear)) * rpm) * 60) / 1000000
    );
}