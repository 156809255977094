import sha256 from 'js-sha256';
import { useEffect, useState } from "react";
import "./sass/App.scss";
import { calcSpeed, calcWheelHeight, calcWheelHeightInch } from "./util/Calc";

function App() {
    const init = {
        tyre_width: 195,
        tyre_height: 65,
        rim_inch: 15,
        drivetrain_gear: 0,
        drivetrain_differential: 0,
        rpm: 800
    };

    const initResult = {
        wheel_height: calcWheelHeight(195, 65, 15),
        wheel_height_inch: calcWheelHeightInch(calcWheelHeight(195, 65, 15)),
        speed: 0
    };

    const [data, setData] = useState({ ...init });
    const [result, setResult] = useState({ ...initResult });

    const [history, setHistory] = useState([]);

    const updateData = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        const newData = { ...data };

        Object.assign(newData, {
            [name]: value
        });

        setData(newData);
    }

    const addToHistory = () => {
        const id = sha256(JSON.stringify(data));

        const newHistoryItem = {
            ...data,
            ...result,
            time: new Intl.DateTimeFormat('rs', {
                hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false
            }).format(new Date()),
            id: id
        };

        const newHistory = [...history];

        newHistory.unshift(newHistoryItem);

        setHistory(newHistory);
    }

    const clearHistory = () => {
        setHistory([]);
    }

    useEffect(() => {
        //
    }, []);

    useEffect(() => {
        const wheelHeight = calcWheelHeight(data.tyre_width, data.tyre_height, data.rim_inch);
        const wheelHeightInch = calcWheelHeightInch(wheelHeight);
        let speed = calcSpeed(wheelHeight, data.drivetrain_gear, data.drivetrain_differential, data.rpm);

        if (speed === Infinity) {
            speed = 0;
        }

        const newResult = { ...result };
        Object.assign(newResult, {
            wheel_height: wheelHeight,
            wheel_height_inch: wheelHeightInch,
            speed: speed
        })
        setResult(newResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="main">
            <h1 className="title">SpeedCalc</h1>
            <div className="form">
                <form id="form">
                    <h2>Točak (dimenzije): </h2>
                    <p>Primer: 195 / 65 / 15</p>
                    <div className="tyre">
                        <input name="tyre_width" type="number" min="145" step="10" value={data.tyre_width} onChange={updateData} />
                        <span className="divider">/</span>
                        <input name="tyre_height" type="number" min="30" step="5" value={data.tyre_height} onChange={updateData} />
                        <span className="divider">/</span>
                        <input name="rim_inch" type="number" min="13" value={data.rim_inch} onChange={updateData} />
                    </div>
                    <h2>Visina točka: </h2>
                    <div className="wheel_height">
                        <h2 id="dimension">{result.wheel_height} mm<br />{result.wheel_height_inch} inch</h2>
                    </div>
                    <h2>Prenosni odnos: </h2>
                    <table>
                        <tbody>
                            <tr>
                                <td>Brzina: </td>
                                <td>
                                    <input name="drivetrain_gear" type="number" min="0" step="0.01" value={data.drivetrain_gear} onChange={updateData} />
                                </td>
                            </tr>
                            <tr>
                                <td>Diferencijal: </td>
                                <td>
                                    <input name="drivetrain_differential" type="number" min="0" step="0.01" value={data.drivetrain_differential} onChange={updateData} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Broj obrtaja: </h2>
                    <input className="rpm" name="rpm" type="number" min="800" value={data.rpm} onChange={updateData} />
                </form>
                <h1>Rezultat: </h1>
                <div className="result">
                    <h2 id="result">{result.speed} kmh</h2>
                </div>
                <div className="save-history">
                    <button onClick={addToHistory}>Sačuvaj u istoriju</button>
                </div>
            </div>
            <div className="history">
                <h1>Istorija:</h1>
                <div className="history-table">
                    {
                        history.length ?
                            (
                                <>
                                    <ul>
                                        {
                                            history.map(item => <li key={item.id}>
                                                <p className="history-item-date">
                                                    = {item.time}
                                                </p>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Točak:</td>
                                                            <td colSpan={2}>{`${item.tyre_width} / ${item.tyre_height} / ${item.rim_inch}`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Visina točka:</td>
                                                            <td colSpan={2}>
                                                                {item.wheel_height} mm<br />
                                                                {item.wheel_height_inch} inch
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan={2}>Prenosni odnos:</td>
                                                            <td>Brzina:</td>
                                                            <td>{item.drivetrain_gear}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Diferencijal:</td>
                                                            <td>{item.drivetrain_differential}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Obrtaji:</td>
                                                            <td colSpan={2}>{item.rpm} rpm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Brzina:</td>
                                                            <td colSpan={2}>{item.speed} kmh</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>)
                                        }
                                    </ul>
                                    <div className="save-history">
                                        <button onClick={clearHistory}>Izbriši istoriju</button>
                                    </div>
                                </>
                            ) :
                            <div>Trenutno nema ništa sačuvano u istoriju.</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
